import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Stars.module.scss';

const Stars = ({ score }) => (
  <div className={cls(styles.root, 'has-text-warning')}>
    {[1, 2, 3, 4, 5].map(star => {
      const threshold = star * 2;

      let type = 'empty';
      if (score >= threshold) {
        type = 'full';
      } else if (score < threshold && score > threshold - 2) {
        type = 'half';
      }
      return (
        <span key={star} className={cls('icon', styles.icon)}>
          <i
            className={
              {
                empty: 'far fa-star',
                half: 'fas fa-star-half-alt',
                full: 'fas fa-star',
              }[type]
            }
          />
        </span>
      );
    })}
  </div>
);

Stars.propTypes = {
  score: PropTypes.number.isRequired,
};

export default Stars;
