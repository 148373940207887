import React from 'react';
import { graphql } from 'gatsby';
import cls from 'classnames';
import { formatReview } from '../formatters/review';
import Body from '../components/Body';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';
import ExternalLink from '../components/ExternalLink';
import Stars from '../components/Stars';
import styles from './ReviewTemplate.module.scss';

const ReviewTemplate = ({ data }) => {
  const {
    title,
    logo,
    url,
    score,
    body,
    metaDescription,
    metaTitle,
  } = formatReview(data);
  return (
    <Layout>
      <Meta
        title={metaTitle || `${title} review`}
        description={metaDescription}
      />
      <Section>
        <ContentWithSidebar
          contentBefore={
            <div className={cls('box', styles.summary)}>
              <img className={styles.logo} src={logo} alt={title} />
              <hr />
              <p className="heading has-text-centered">Score</p>
              <div className="level">
                <div className="level-item has-text-centered">
                  <p className="title">{score}</p>
                </div>
                <div className="level-item has-text-centered">
                  <Stars score={score} />
                </div>
              </div>
              <hr />
              <ExternalLink
                className={cls(
                  'button is-fullwidth has-text-white has-text-weight-bold',
                  styles.visit,
                )}
                href={url}
              >
                Visit site
              </ExternalLink>
            </div>
          }
        >
          <h1>{title} Review</h1>
          <Body content={body} />
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default ReviewTemplate;

export const reviewFragment = graphql`
  fragment ReviewFragment on MarkdownRemark {
    id
    fields {
      path
      slug
      frontmattermd {
        description {
          html
        }
        summary {
          html
        }
      }
    }
    frontmatter {
      title
      logo
      score
      url
      metaDescription
      metaTitle
    }
    html
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...ReviewFragment
    }
  }
`;
